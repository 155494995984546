import Env from "../config/env.config"
import { strings as commonStrings } from "../lang/common"
import { strings } from "../lang/cars"
import { toast } from 'react-toastify'
import { pl, fr, enUS } from 'date-fns/locale'
import * as UserService from '../services/UserService'
import * as CarService from '../services/CarService'

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isDate = (date) => {
    return date instanceof Date && !isNaN(date.valueOf())
}

export const info = (message) => {
    toast(message, { type: 'info' })
}

export const error = (err, message) => {
    if (err && console && console.error) console.error(err)
    if (message) {
        toast(message, { type: 'error' })
    } else {
        toast(commonStrings.GENERIC_ERROR, { type: 'error' })
    }
}

export const joinURL = (part1, part2) => {
    if (part1.charAt(part1.length - 1) === '/') {
        part1 = part1.substr(0, part1.length - 1)
    }
    if (part2.charAt(0) === '/') {
        part2 = part2.substr(1)
    }
    return part1 + '/' + part2
}

export const isNumber = (val) => {
    return /^-?\d+$/.test(val)
}

export const isInteger = (val) => {
    return /^\d+$/.test(val)
}

export const isYear = (val) => {
    return /^\d{2}$/.test(val)
}

export const isCvv = (val) => {
    return /^\d{3,4}$/.test(val)
}

export const getCarType = (type) => {
    switch (type) {
        case Env.CAR_TYPE.DIESEL:
            return strings.DIESEL

        case Env.CAR_TYPE.GASOLINE:
            return strings.GASOLINE

        default:
            return ''
    }
}

export const getCarTypeShort = (type) => {
    switch (type) {
        case Env.CAR_TYPE.DIESEL:
            return strings.DIESEL_SHORT

        case Env.CAR_TYPE.GASOLINE:
            return strings.GASOLINE_SHORT

        default:
            return ''
    }
}

export const getGearboxType = (type) => {
    switch (type) {
        case Env.GEARBOX_TYPE.MANUAL:
            return strings.GEARBOX_MANUAL

        case Env.GEARBOX_TYPE.AUTOMATIC:
            return strings.GEARBOX_AUTOMATIC

        default:
            return ''
    }
}

export const getGearboxTypeShort = (type) => {
    switch (type) {
        case Env.GEARBOX_TYPE.MANUAL:
            return strings.GEARBOX_MANUAL_SHORT

        case Env.GEARBOX_TYPE.AUTOMATIC:
            return strings.GEARBOX_AUTOMATIC_SHORT

        default:
            return ''
    }
}

export const getFuelPolicy = (type) => {
    switch (type) {
        case Env.FUEL_POLICY.LIKE_FOR_LIKE:
            return strings.FUEL_POLICY_LIKE_FOR_LIKE

        case Env.FUEL_POLICY.FREE_TANK:
            return strings.FUEL_POLICY_FREE_TANK

        default:
            return ''
    }
}

export const getCarTypeTooltip = (type) => {
    switch (type) {
        case Env.CAR_TYPE.DIESEL:
            return strings.DIESEL_TOOLTIP

        case Env.CAR_TYPE.GASOLINE:
            return strings.GASOLINE_TOOLTIP

        default:
            return ''
    }
}

export const getGearboxTooltip = (type) => {
    switch (type) {
        case Env.GEARBOX_TYPE.MANUAL:
            return strings.GEARBOX_MANUAL_TOOLTIP

        case Env.GEARBOX_TYPE.AUTOMATIC:
            return strings.GEARBOX_AUTOMATIC_TOOLTIP

        default:
            return ''
    }
}

export const getSeatsTooltip = (seats) => {
    return `${strings.SEATS_TOOLTIP_1}${seats} ${strings.SEATS_TOOLTIP_2}`
}

export const getDoorsTooltip = (doors) => {
    return `${strings.DOORS_TOOLTIP_1}${doors} ${strings.DOORS_TOOLTIP_2}`
}

export const getFuelPolicyTooltip = (fuelPolicy) => {
    switch (fuelPolicy) {
        case Env.FUEL_POLICY.LIKE_FOR_LIKE:
            return strings.FUEL_POLICY_LIKE_FOR_LIKE_TOOLTIP

        case Env.FUEL_POLICY.FREE_TANK:
            return strings.FUEL_POLICY_FREE_TANK_TOOLTIP

        default:
            return ''
    }
}

export const getMileage = (mileage) => {
    if (mileage === -1) {
        return strings.UNLIMITED
    } else {
        return `${mileage} ${strings.MILEAGE_UNIT}`
    }
}

export const getMileageTooltip = (mileage, fr) => {
    if (mileage === -1) {
        return `${strings.MILEAGE} ${strings.UNLIMITED.toLocaleLowerCase()}.`
    } else {
        return `${strings.MILEAGE}${fr ? ' : ' : ': '}${mileage} ${strings.MILEAGE_UNIT}`
    }
}

export const admin = (user) => {
    return user && user.type === Env.RECORD_TYPE.ADMIN
}

export const getBookingStatus = (status) => {
    switch (status) {
        case Env.BOOKING_STATUS.VOID:
            return commonStrings.BOOKING_STATUS_VOID

        case Env.BOOKING_STATUS.PENDING:
            return commonStrings.BOOKING_STATUS_PENDING

        case Env.BOOKING_STATUS.DEPOSIT:
            return commonStrings.BOOKING_STATUS_DEPOSIT

        case Env.BOOKING_STATUS.PAID:
            return commonStrings.BOOKING_STATUS_PAID

        case Env.BOOKING_STATUS.RESERVED:
            return commonStrings.BOOKING_STATUS_RESERVED

        case Env.BOOKING_STATUS.CANCELLED:
            return commonStrings.BOOKING_STATUS_CANCELLED

        default:
            return ''
    }
}

export const formatNumber = (n) => {
    return n > 9 ? '' + n : '0' + n
}

export const arrayEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false
    }
    return true
}

export const carsEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; i++) {
        const car = a[i]
        if (b.filter(c => c._id === car._id).length === 0) return false
    }
    return true
}

export const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export const cloneArray = (arr) => {
    if (typeof arr === 'undefined') return undefined
    if (arr == null) return null
    return [...arr]
}

export const filterEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false

    if (a.from !== b.from) return false
    if (a.to !== b.to) return false
    if (a.pickupLocation !== b.pickupLocation) return false
    if (a.dropOffLocation !== b.dropOffLocation) return false
    return a.keyword === b.keyword;
}

export const getBookingStatuses = () => {
    return [
        { value: Env.BOOKING_STATUS.VOID, label: commonStrings.BOOKING_STATUS_VOID },
        { value: Env.BOOKING_STATUS.PENDING, label: commonStrings.BOOKING_STATUS_PENDING },
        { value: Env.BOOKING_STATUS.DEPOSIT, label: commonStrings.BOOKING_STATUS_DEPOSIT },
        { value: Env.BOOKING_STATUS.PAID, label: commonStrings.BOOKING_STATUS_PAID },
        { value: Env.BOOKING_STATUS.RESERVED, label: commonStrings.BOOKING_STATUS_RESERVED },
        { value: Env.BOOKING_STATUS.CANCELLED, label: commonStrings.BOOKING_STATUS_CANCELLED }
    ]
}

export const days = (from, to) => {
    if (!from || !to) return 0
    const fromMidnight = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0)
    const toMidnight = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 0, 0, 0)
    return (Math.ceil((toMidnight.getTime() - fromMidnight.getTime()) / (1000 * 3600 * 24))) + 1
}

export const price = async (car, from, to, booking) => {
    const price = await CarService.getCarPrice(car._id, from, to, booking.additionalDriver)
    return price.totalPrice
}

export const getDays = (days) => {
    return `${strings.PRICE_DAYS_PART_1} ${days} ${strings.PRICE_DAYS_PART_2}${days > 1 ? '' : ''}`
}

export const getDaysShort = (days) => {
    return `${days} ${strings.PRICE_DAYS_PART_2}${days > 1 ? '' : ''}`
}

export const flattenCompanies = (companies) => {
    return companies.map(company => company._id)
}

export const getUserTypes = () => {
    return [
        { value: Env.RECORD_TYPE.ADMIN, label: commonStrings.RECORD_TYPE_ADMIN },
        { value: Env.RECORD_TYPE.COMPANY, label: commonStrings.RECORD_TYPE_COMPANY },
        { value: Env.RECORD_TYPE.USER, label: commonStrings.RECORD_TYPE_USER },
    ]
}

export const getUserType = (status) => {
    switch (status) {
        case Env.RECORD_TYPE.ADMIN:
            return commonStrings.RECORD_TYPE_ADMIN

        case Env.RECORD_TYPE.COMPANY:
            return commonStrings.RECORD_TYPE_COMPANY

        case Env.RECORD_TYPE.USER:
            return commonStrings.RECORD_TYPE_USER

        default:
            return ''
    }
}


export const getBirthDateError = (minimumAge) => {
    return `${commonStrings.BIRTH_DATE_NOT_VALID_PART1} ${minimumAge} ${commonStrings.BIRTH_DATE_NOT_VALID_PART2}`
}

// TODO: create monorepo
/**
 * @param user
 * @return {string}
 */
export function getUserLang(user) {
    if(user && typeof user === 'object' && 'language' in user && typeof user.language === 'string') {
        if(['ar','pl','fr','en'].includes(user.language)) {
            return user.language
        }
    }
    return 'en';
}

export const getCalendarLocale = () => {
    switch (UserService.getLanguage()) {
        case 'pl': return pl
        case 'fr': return fr
    }
    return enUS
}